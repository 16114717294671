<template>
  <div class="overflow-x-auto">
    <v-card
      class="d-flex flex-column mt-2"
      style="height: calc(100vh - 182px); min-width: 1240px;"
    >
      <div class="virtual-table header mr-5">
        <div class="item-center item-id">ID</div>
        <div class="item-center item-percent-large">Nome</div>
        <div class="item-center item-percent-small" v-if="!$route.query.clientId">
          Cliente
        </div>
        <div class="item-center item-percent-large">Endereço</div>
        <div class="item-center item-percent-large">Modelo</div>
        <div class="item-center item-zone">Zona</div>
        <div class="item-center flex-grow-1">Estado Câmera/Eventos</div>
        <div class="item-center item-actions justify-center">Ações</div>
      </div>

      <v-virtual-scroll
        v-if="items.length"
        :items="items"
        :item-height="$store.getters.getUsuario.level == 1 ? 80: 40"
        class="flex-grow-1"
      >
        <template v-slot:default="{ item }">
          <div class="virtual-table" :key="item.id" :class="getColorRow(item)">
            <div class="item-center item-id">{{ item.id }}</div>
            <div class="item-center item-percent-large">
              <TextToolTip :text="item.name" />
            </div>
            <div
              class="item-center item-percent-small"
              v-if="!$route.query.clientId"
            >
              <TextToolTip
                :text="$store.getters.getClienteNameById(item.clientId)"
              />
            </div>
            <div class="item-center item-percent-large">
              <TextToolTip :text="item.url" />
            </div>
            <div class="item-center item-percent-large">
              <TextToolTip
                :text="$store.getters.getCameraTypeById(item.modelId)"
              />
            </div>
            <div class="item-center item-zone">{{ item.zone }}</div>
            <div class="d-inline-block text-truncate">
              <!-- <div class="flex-grow-1"> -->
              <v-switch
                dense
                hide-details
                :input-value="item.deletedAt ? false : true"
                @change="$emit('ativa', item)"
                :label="`${
                  item.deletedAt ? 'Câmera desativada' : 'Câmera ativa'
                }`"
                class="my-2"
              >
                a</v-switch
              >
              <v-switch
                dense
                hide-details
                :input-value="item.sendEvent"
                @change="$emit('monitoramento', item)"
                :label="`${
                  item.sendEvent ? 'Eventos ativos' : 'Eventos desativados'
                }`"
                v-if="$store.getters.getUsuario.level == 1"
                class="my-2"
              ></v-switch>
            </div>
            <div class="item-center item-actions">
              <StateToolTip :item="item" />
              <StatusToolTip :item="item" />
              <v-menu top open-on-hover offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    <v-icon class="pr-1">mdi-menu</v-icon> Menu
                  </v-btn>
                </template>
                <!-- motra o nome das cameras -->
                <v-list class="custom-menu grey darken-3" dense >
                  <v-list-item class="grey darken-2">
                    <v-list-item-content>
                      <v-list-item-title class="d-inline-block text-truncate ">
                        <div class="d-flex justify-center text-button ">
                          {{ item.name }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <!-- menus -->
                  <v-list-item @click="$emit('verCamera', item)">
                    <v-list-item-icon><v-icon>mdi-eye</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        perm.getText("btnViewCam")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="$emit('openDialoag', item)">
                    <v-list-item-icon
                      ><v-icon>mdi-pencil</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        perm.getText("btnEditCam")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="$emit('openDialoagAgenda', item)">
                    <v-list-item-icon
                      ><v-icon>mdi-calendar</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        perm.getText("btnScheduleCam")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="$emit('openCamera', item)">
                    <v-list-item-icon><v-icon>mdi-cctv</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        perm.getText("btnOpenCam")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="$emit('openDialogConfig', item)">
                    <v-list-item-icon><v-icon>mdi-cog</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        perm.getText("btnConfCam")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="$emit('openDialogStart', item)"
                    v-if="[4, 5, 6, 7, 8, 9].includes(item.modelId)"
                  >
                    <v-list-item-icon><v-icon>mdi-play</v-icon></v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        perm.getText("btnStartCam")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="forceDetection(item)"
                    v-if="[4, 5, 6, 7, 8, 9].includes(item.modelId)"
                  >
                    <v-list-item-icon
                      ><v-icon>mdi-run-fast</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title
                        >Forçar detecção de movimento</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="$emit('openDialogSnap', item)">
                    <v-list-item-icon
                      ><v-icon>mdi-image-search</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        perm.getText("btnSnapCam")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="$emit('openDialogVideo', item)"
                    v-if="[4, 5, 6, 7, 8, 9].includes(item.modelId)"
                  >
                    <v-list-item-icon
                      ><v-icon>mdi-map-marker-path</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        perm.getText("btnDrawCam")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="$emit('viewEvt', item)">
                    <v-list-item-icon
                      ><v-icon>mdi-party-popper</v-icon></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title>Eventos da câmera</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item
                    @click="$emit('action', item)"
                    v-if="[1, 2, 10].includes(item.modelId)"
                  >
                    <v-list-item-icon
                      ><v-icon
                        >mdi-console-network-outline</v-icon
                      ></v-list-item-icon
                    >
                    <v-list-item-content>
                      <v-list-item-title>Ações da câmera</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </template>
      </v-virtual-scroll>
      <span v-else class="d-block pa-4 text-center"
        >Nenhuma câmera encontrada</span
      >
    </v-card>
  </div>
</template>

<script>
import PermissoesHelper from "@/Utils/Permissoes";
import StatusToolTip from "./StatusToolTip.vue";
import StateToolTip from "./StateToolTip.vue";
import TextToolTip from "./TextToolTip.vue";
export default {
  name: "TableCameras",
  components: {
    StatusToolTip,
    TextToolTip,
    StateToolTip,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      perm: PermissoesHelper,
      panel: true,
      screenWidth: 0,
    };
  },
  methods: {
    getColorRow(item) {
      if (item.deletedAt) {
        return "";
      }
      if (this.$store.getters.getCameraStatusById(item.id).conected) {
        return "green darken-4";
      } else {
        return "red darken-4";
      }
    },
    searchCam(value) {
      this.$store.dispatch("setFilterCam", value || "");
    },
    btnEnabled(elName, modelId) {
      return (
        this.perm.getEnabled(elName) &&
        this.$store.getters.getCamTypeBtnsEnabledById(modelId, elName)
      );
    },
    showLine(item) {
      if (this.onlyActive && item.deletedAt) {
        return false;
      }
      if (
        this.onlyConnected &&
        !this.$store.getters.getCameraStatusById(item.id).conected
      ) {
        return false;
      }
      return true;
    },
    forceDetection(item) {
      console.log(item);
      this.$store.dispatch("forceDetecion", item);
    },
  },
  mounted() {
    this.screenWidth = this.$vuetify.breakpoint.width;
  },
};
</script>

<style scoped>
.custom-menu .v-list-item__icon {
  margin-right: 20px !important;
}

.virtual-table {
  display: flex;
  border-bottom: thin solid rgba(255, 255, 255, 0.12);
  font-size: 0.875rem;
}

.virtual-table > div {
  padding: 0 16px;
}

.virtual-table.header > div {
  font-size: 0.75rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  height: 48px;
}

.virtual-table .item-center {
  display: flex;
  align-items: center;
}

.virtual-table .item-id {
  width: 70px;
}

.virtual-table .item-percent-large {
  width: 16%;
}

.virtual-table .item-percent-small {
  width: 12%;
}

.virtual-table .item-zone {
  width: 100px;
}

.virtual-table .item-actions {
  width: 170px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: gray rgb(82, 82, 82);
}
*::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
*::-webkit-scrollbar-track {
  background: rgb(161, 161, 161);
}
*::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px;
  border: 3px solid rgb(82, 82, 82);
}
</style>