<template>
  <!-- tooltip do status -->
  <v-tooltip left color="grey darken-4">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon :color="getColor()">{{ getIcon() }}</v-icon>
      </v-btn>
    </template>
    <!-- card com os dados -->
    <v-card outlined>
      <v-card-text>
        <v-row class="d-flex align-center">
          <v-col >
            <p class="font-weight-bold">Estado:</p>
          </v-col>
          <v-col cols="auto">
            <p>{{ getState() }}</p>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="d-flex align-center">
          <v-col >
            <p class="font-weight-bold">Expira:</p>
          </v-col>
          <v-col cols="auto">
            <p>
              {{
                item.stateUntil
                  ? new Date(item.stateUntil).toLocaleString()
                  : "Nunca"
              }}
            </p>
          </v-col>
        </v-row>
        <v-divider v-if="this.item.inPeriod"></v-divider>
        <v-row v-if="this.item.inPeriod" class="d-flex align-center">
          <v-col >
            <p class="font-weight-bold">No periodo:</p>
          </v-col>
          <v-col cols="auto"> <p>SIM</p> </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-tooltip>
</template>

<script>
export default {
  name: "StatusToolTip",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getIcon() {
      let s = this.item.state;
      switch (s) {
        case 1:
          return "mdi-lock";
        case 2:
          return "mdi-lock-open";
        default:
          return "mdi-clock";
      }
    },
    getState() {
      let s = this.item.state;
      switch (s) {
        case 1:
          return "Armado";
        case 2:
          return "Desarmado";
        default:
          return "Agenda";
      }
    },
    getColor() {
      let s = this.item.state;
      switch (s) {
        case 1:
          return "red";
        case 2:
          return "green accent-3";
        default:
          return this.item.inPeriod ? "blue" : "white";
      }
    },
  },
};
</script>

<style>
</style>