<template>
  <div>
    <v-dialog v-model="value" persistent scrollable>
      <v-card>
        <v-card-title> Configuração da Câmera - {{ camera.name }}</v-card-title>
        <v-card-text>
          <v-row>
            <!-- Configuração dos eventos da intelbras -->
            <v-col
              v-if="
                camera.modelId == 1 ||
                camera.modelId == 2 ||
                camera.modelId == 3 ||
                camera.modelId == 6
              "
            >
              <v-card outlined>
                <v-card-title>Configuração de Evento</v-card-title>
                <v-card-subtitle>
                  Selecione os Eventos que deseja monitorar
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        small-chips
                        chips
                        multiple
                        deletable-chips
                        prepend-inner-icon="mdi-refresh"
                        :items="eventsAvaliable"
                        @click:prepend-inner="refreshEvents"
                        label="Eventos monitorados da Câmera"
                        hint="Selecione os eventos que deseja monitorar"
                        persistent-hint
                        v-model="eventsSelected"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- botoes de sincronização de desenhos hiki / intelbras -->
                  <v-row >
                    <v-col v-if="[1,2].includes(camera.modelId)">
                      <v-btn color="warning" @click="syncCam"
                        >Sincronizar câmera</v-btn
                      >
                    </v-col>
                    <v-col v-if="[2].includes(camera.modelId)">
                      <AudioConverter
                        v-model="showAudioConverter"
                        target="hikivision"
                      />
                      <v-btn
                        color="success"
                        @click="showAudioConverter = true"
                        class="mx-2"
                      >
                        Otimizador de Audio
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- configurações para cameras sem IA -->
            <v-col v-if="models.includes(camera.modelId)">
              <!-- <v-col v-if="camera.modelId == 3"> -->
              <v-card outlined>
                <v-card-title>Configurações da IA</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        small-chips
                        chips
                        multiple
                        deletable-chips
                        :items="coconames"
                        label="Tipos que serão monitorados"
                        hint="Selecione os tipos que deseja monitorar"
                        persistent-hint
                        v-model="targetEvent"
                        outlined
                      ></v-autocomplete>
                      <small v-show="targetEvent.length != 0">
                        * Quando selecionados estes desabilita a exclusão
                      </small>
                    </v-col>
                    <v-col>
                      <v-autocomplete
                        :disabled="targetEvent.length != 0"
                        small-chips
                        chips
                        multiple
                        deletable-chips
                        :items="coconames"
                        label="Tipos que não serão monitorados"
                        hint="Selecione os tipos que deseja desconsiderar"
                        persistent-hint
                        v-model="names"
                        outlined
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <!-- <hr> -->
                  <v-row>
                    <v-col cols="12">
                      <v-subheader class="pl-0">
                        Confiança mínima para detecção
                      </v-subheader>
                      <v-slider
                        v-model="confidence"
                        thumb-label="always"
                        min="10"
                        max="90"
                      ></v-slider>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-card outlined class="my-2" v-if="camera.modelId == 3">
            <v-card-title>Configurações de LPR</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Velocidade máxima em Km/h"
                    v-model.number="camera.config.maxSpeed"
                    hint="Velocidade igual ou acima deste valor gera o evento"
                    type="number"
                    min="0"
                  ></v-text-field>
                </v-col>
              </v-row> </v-card-text
          ></v-card>
          <!-- configurações para o app -->
          <v-card
            outlined
            class="my-2"
            v-show="camera.modelId == 1 || camera.modelId == 2"
          >
            <v-card-title>Configurações para o APP</v-card-title>
            <v-card-text>
              <!-- <v-row>
                <v-col>
                  <v-text-field
                    label="PORTA RTSP DA CAMERA para visualizar esta camera no app"
                    v-model.number="camera.config.rtspPort"
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col>
                  <v-autocomplete
                    :items="[
                      { value: 1, text: 'Intelbras / Dahua' },
                      { value: 2, text: 'Hikivision' },
                    ]"
                    label="MODELO DO DVR"
                    persistent-hint
                    v-model="camera.config.dvrModel"
                    auto-select-first
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-text-field
                    label="ENDEREÇO DO DVR"
                    v-model="camera.config.dvrAddress"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="PORTA WEB DO DVR"
                    v-model.number="camera.config.dvrPort"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="PORTA DE RTSP DO DVR"
                    v-model.number="camera.config.dvrRtspPort"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="USUÁRIO DO DVR"
                    v-model.number="camera.config.dvrUser"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="SENHA DO DVR"
                    v-model.number="camera.config.dvrPass"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete
                    prepend-inner-icon="mdi-refresh"
                    :items="channels"
                    @click:prepend-inner="loadChannels"
                    label="CANAL DO DVR"
                    hint="Depois de preencher os dados do DVR clique no icone para carregar os canais"
                    persistent-hint
                    v-model="camera.config.dvrChannel"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- Configurações Avançadas -->
          <v-row>
            <v-col>
              <v-card outlined>
                <v-card-title>Configurações Avançadas</v-card-title>
                <v-card-subtitle>
                  NÃO ALTERE NADA SEM SER INSTRUIDO
                </v-card-subtitle>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Largura da Detecção"
                        v-model.number="camera.config.detectionLimits.width"
                        hint="Largura da Detecção em pixels"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Altura da Detecção"
                        v-model.number="camera.config.detectionLimits.height"
                        hint="Altura da Detecção em pixels"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        label="Tempo de Reconexão"
                        v-model="camera.config.reconectTimeoutSeconds"
                        hint="Tempo de Reconexão em SEGUNDOS"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        label="Tempo para REENVIAR Eventos de Conexão"
                        v-model="camera.config.eventsConectionRepeatMinutes"
                        hint="Tempo para REENVIAR Eventos de Conexão em MINUTOS"
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <!-- Configurações de Região zona -->
          <v-row v-if="[1].includes(camera.modelId)">
            <v-col>
              <ConfigRegZone :regionZones="regionZones" />
            </v-col>
          </v-row>
          <!-- Configurações de Portaria -->
          <v-row v-if="camera.modelId == 1">
            <v-col>
              <ConfigCarps :carpsConfig="carpsConfig" :coconames="coconames" />
            </v-col>
          </v-row>
          <!-- Configurações de ronda virtual -->
          <v-row v-if="camera.modelId == 1">
            <v-col>
              <ConfigPatrol
                :patrolConfig="patrolConfig"
                :coconames="coconames"
              />
            </v-col>
          </v-row>
          <v-row v-if="[1, 2].includes(camera.modelId)">
            <v-col>
              <ConfigDoubleCheck
                :doubleCheckConfig="doubleCheckConfig"
                :coconames="coconames"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Fechar </v-btn>
          <v-btn color="warning darken-1" text @click="save"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ConfigCarps from "./partials/ConfigCarps";
import ConfigPatrol from "./partials/ConfigPatrol";
import ConfigRegZone from "./partials/ConfigRegZon.vue";
import ConfigDoubleCheck from "./partials/ConfigDoubleCheck.vue";
import AudioConverter from "@/components/AudioConverter.vue";

export default {
  name: "DialogConfig",
  props: ["value", "hash", "camera"],
  components: {
    ConfigCarps,
    ConfigPatrol,
    ConfigRegZone,
    AudioConverter,
    ConfigDoubleCheck,
  },
  data: () => ({
    eventsSelected: [],
    eventsAvaliable: [],
    targetEvent: [],
    coconames: [],
    names: [],
    models: [6, 7, 9],
    confidence: 50,
    addRuleDialog: false,
    addRulePatrolDialog: false,
    carpsConfig: {
      enabled: false,
      rules: [],
    },
    patrolConfig: {
      enabled: false,
      rules: [],
    },
    regionZones: [],
    doubleCheckConfig: {
      enabled: false,
      rules: {},
    },
    draws: [],
    showAudioConverter: false,
    channels: [],
  }),
  methods: {
    close() {
      this.$emit("input", false);
    },
    save() {
      this.camera.config.eventsSelected = this.eventsSelected;
      this.camera.config.eventsAvaliable = this.eventsAvaliable;
      this.camera.config.exceptions = this.names;
      this.camera.config.confidence = this.confidence;
      this.camera.config.targetEvent = this.targetEvent;
      this.camera.config.carpsConfig = this.carpsConfig;
      this.camera.config.patrolConfig = this.patrolConfig;
      this.camera.config.regionZones = this.regionZones;
      this.camera.config.doubleCheckConfig = this.doubleCheckConfig;
      this.$emit("saveConfig", this.camera);
    },
    refreshEvents() {
      this.$store.dispatch("loadCameraEvent", {
        ...this.camera,
        cb: (evt) => {
          this.eventsAvaliable = evt;
        },
      });
    },
    syncCam() {
      this.$store.dispatch("syncCam", {
        ...this.camera,
        cb: () => {
          this.close();
        },
      });
    },
    getCocoNames() {
      this.$http("camera/coconames").then((resp) => {
        this.coconames = [];
        resp.data.forEach((element) => {
          this.coconames.push({
            value: element.name,
            text: `${element.traducao} (${element.name})`,
          });
        });
      });
    },
    loadChannels() {
      this.$http
        .post("dvrrecord/info", {
          ip: this.camera.config.dvrAddress,
          port: this.camera.config.dvrPort,
          user: this.camera.config.dvrUser,
          password: this.camera.config.dvrPass,
          dvrModel: this.camera.config.dvrModel,
        })
        .then((resp) => {
          this.channels = [];
          this.camera.config.dvrInfo = resp.data;
          if (resp.data.channelNames) {
            this.channels = resp.data.channelNames;
          }
          this.camera.config.dvrChannels = this.channels;
        });
    },
    getPortLabel() {
      if (!this.camera.config.dvrModel) return "Selecione o modelo do DVR";
      if (this.camera.config.dvrModel == 1) {
        return "PORTA DE SERVICO DO DVR";
      } else {
        return "PORTA WEB DO DVR";
      }
    },
  },
  watch: {
    value: function (val) {
      if (val) {
        this.eventsSelected = this.camera.config.eventsSelected;
        this.eventsAvaliable = this.camera.config.eventsAvaliable;
        this.names = this.camera.config.exceptions;
        this.targetEvent = this.camera.config.targetEvent || [];
        this.carpsConfig = this.camera.config.carpsConfig || {
          enabled: false,
          rules: [],
        };
        this.patrolConfig = this.camera.config.patrolConfig || {
          enabled: false,
          rules: [],
        };
        this.doubleCheckConfig = this.camera.config.doubleCheckConfig || {
          enabled: false,
          rules: {},
        };
        this.regionZones = this.camera.config.regionZones || [];
        this.getCocoNames();
        if (
          this.camera.config &&
          Array.isArray(this.camera.config.dvrChannels)
        ) {
          this.channels = this.camera.config.dvrChannels;
        }
      }
    },
  },
};
</script>

<style scoped></style>